// @use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();

/*
*'--primary': '#394160' from ../theme/default-theme.ts
*/
$md-custom-primary: (50 : #c5cadd,
100 : #959ec0,
200 : #727eab,
300 : #505b86,
400 : #444e73,
500 : #394160,
600 : #2e344d,
700 : #22273a,
800 : #171a26,
900 : #0b0d13,
A100 : #b0bbe8,
A200 : #6078d2,
A400 : #3d4e8f,
A700 : #3e4974,
contrast: (50 : #000000,
100 : #000000,
200 : #ffffff,
300 : #ffffff,
400 : #ffffff,
500 : #ffffff,
600 : #ffffff,
700 : #ffffff,
800 : #ffffff,
900 : #ffffff,
A100 : #000000,
A200 : #ffffff,
A400 : #ffffff,
A700 : #ffffff,
));

/*
*'--secondary': '#c8c618' from ../theme/default-theme.ts
 */
$md-custom-secondary: (50 : #fdfdec,
100 : #f5f4a8,
200 : #efed76,
300 : #e7e536,
400 : #e3e11b,
500 : #c8c618,
600 : #adab15,
700 : #919011,
800 : #76750e,
900 : #5b5a0b,
A100 : #ffffe0,
A200 : #fffd7a,
A400 : #f7f51c,
A700 : #e5e314,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #000000,
500 : #000000,
600 : #000000,
700 : #000000,
800 : #ffffff,
900 : #ffffff,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

/*
  #f07746
 */
$md-custom-warn: (50 : #ffffff,
100 : #fef7f5,
200 : #fad1c1,
300 : #f5a17f,
400 : #f28c62,
500 : #f07746,
600 : #ee622a,
700 : #e65013,
800 : #ca4610,
900 : #ae3c0e,
A100 : #ffffff,
A200 : #ffded0,
A400 : #ff956a,
A700 : #f68659,
contrast: (50 : #000000,
100 : #000000,
200 : #000000,
300 : #000000,
400 : #000000,
500 : #000000,
600 : #000000,
700 : #ffffff,
800 : #ffffff,
900 : #ffffff,
A100 : #000000,
A200 : #000000,
A400 : #000000,
A700 : #000000,
));

$custom-theme-primary: mat.define-palette($md-custom-primary);
$custom-theme-accent: mat.define-palette($md-custom-secondary);
$custom-theme-warn: mat.define-palette($md-custom-warn);

$custom-theme: mat.define-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);
@include mat.all-component-themes($custom-theme);
