@use "@angular/material" as mat;

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./app/utils/material/material-custom-theme";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
@import "~angular-calendar/css/angular-calendar.css";
$color-blue: #202e3f;
$color-blue-hover: #000;
$color-green: #c9c615;
$color-green-hover: #9c9a10;

// @import '@angular/material/theming';
// @include mat.core();

body {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  height: 100%;
  background: #fafafa;

  .container {
    padding-top: 30px;
  }

  .container-fluid {
    margin-top: 40px;
    padding-top: 20px;
    position: relative;
    padding-left: 60px;
    height: 93vh;
  }

  .container-nopadding {
    padding-left: 20px;
  }

  .box {
    background: #f7f7f7;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .btn {
    font-size: 20px;
    height: 40px;
    padding: 0px 20px 0px 20px;
  }

  .btn-primary-yellow {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    color: white;
    font-weight: bolder;
    font-size: 18px;
  }

  .btn-primary-yellow:hover {
    background-color: $color-blue-hover;
    border-color: $color-blue-hover;
  }

  .btn-primary {
    background-color: $color-blue !important;
    border-color: $color-blue !important;
    color: white;
    font-weight: bolder;
    font-size: 18px;
  }

  .btn-primary:hover {
    background-color: $color-blue-hover;
    border-color: $color-blue-hover;
  }

  .btn-secondary {
    background-color: $color-green;
    border-color: $color-green;
    color: white;
    font-weight: bolder;
    font-size: 18px;
  }

  .btn-secondary:hover {
    background-color: $color-green-hover;
    border-color: $color-green-hover;
  }

  .btn-outline {
    border: 2px solid $color-blue;
    color: $color-blue;
    font-weight: bolder;
    font-size: 18px;
  }

  .btn-outline:hover {
    background-color: $color-blue;
    color: white;
  }

  @-moz-document url-prefix() {
    .container-fluid {
      height: initial;
    }
  }

  @media only screen and (max-width: 576px) {
    .healthscreeningPage {
      .col-12 {
        padding: 0;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $color-blue;
    font-weight: bold;
  }

  h5 {
    font-size: 1.2em;
  }

  h6 {
    color: $color-blue;
    font-weight: bold;
  }

  .row {
    margin-bottom: 15px;
  }

  a {
    cursor: pointer;
  }

  mat-divider {
    position: relative !important;
    margin: 10px 0 10px 0 !important;
  }

  .mat-form-field {
    width: 100%;
  }

  .mat-dialog-title {
    text-align: center;
  }

  .mat-dialog-actions {
    display: block;
    text-align: center;
  }

  .mat-menu-panel {
    background-color: var(--hover);

    .mat-menu-content {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;

    th.mat-header-cell {
      background: $color-blue;
      color: white;
      font-weight: bold;
    }

    tr.mat-header-row {
      height: 40px;
    }

    tr.mat-row {
      // vertical-align: top;
      color: $color-blue;
    }

    tr.mat-row:hover {
      background: $color-green;
    }

    td.mat-cell {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 0.8em;
    }

    td.actions-col {
      vertical-align: middle;
      text-align: right;
    }

    td.confirm-col {
      text-align: right;
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  mat-paginator {
    color: var(--primary);
    border-radius: 0 0 10px 10px;
    -webkit-border-radius: 0 0 10px 10px;
    -moz-border-radius: 0 0 10px 10px;
    -ms-border-radius: 0 0 10px 10px;
    -o-border-radius: 0 0 10px 10px;
  }

  // i {
  //   vertical-align: bottom;
  // }

  .mat-snack-bar-container {
    padding: 0;
    min-height: 0;
  }

  .mat-calendar-body-cell-content:hover {
    background-color: var(--secondary) !important;
  }

  .mat-calendar-body-selected {
    background-color: var(--primary);
  }

  .mat-calendar-table-header {
    color: var(--primary);
  }

  .mat-calendar-period-button:hover {
    background-color: var(--secondary);
  }

  ngx-material-timepicker {
    .timepicker__header {
      background: var(--primary) !important;
    }

    .clock-face__number > span.active {
      background: var(--primary) !important;
    }

    .clock-face__clock-hand {
      background: var(--primary) !important;
    }

    .clock-face__number--outer > span {
      color: var(--primary) !important;
    }

    .clock-face__number--outer > span.active {
      color: #fff !important;
    }

    .timepicker__actions {
      div:first-child {
        ngx-material-timepicker-button {
          .timepicker-button {
            color: var(--hover) !important;
          }
        }
      }

      div:last-child {
        ngx-material-timepicker-button {
          .timepicker-button {
            color: var(--primary) !important;
          }
        }
      }
    }
  }

  ngx-material-timepicker-toggle {
    float: right;

    .ngx-material-timepicker-toggle {
      height: 18px;
      padding-top: 0 !important;
    }

    svg {
      height: 20px;
      width: 20px;
      fill: var(--hover);
    }
  }

  ngx-material-timepicker-container {
    .timepicker-overlay {
      z-index: 1000 !important;
    }
  }

  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 16px;
    height: 16px;
  }

  .mat-radio-label-content {
    padding-left: 0;

    label {
      margin-top: 9px;
    }
  }
}

mat-bottom-sheet-container {
  padding-top: 0 !important;
  width: 80vw;
  height: 80vh;
}

ag-grid-angular {
  .ag-header-cell,
  .ag-cell {
    padding-left: 3px !important;
    padding-right: 0px !important;
    line-height: 30px !important;
    font-size: 10px;
  }

  .ag-paging-panel {
    width: 100%;
    background: var(--primary);
    color: #fff !important;

    .ag-icon {
      color: #fff;
    }

    .ag-paging-button.ag-disabled .ag-icon {
      color: var(--hover) !important;
    }
  }

  .ag-cell {
    border-right: 1px solid #e2e2e2 !important;
  }

  .ag-header-cell {
    background: var(--primary) !important;
    color: #fff !important;
  }

  .ag-cell-inline-editing {
    min-width: 200px;
  }

  .ag-cell-focus {
    border: 1px solid #3f51b5 !important;
  }

  .ag-overlay-panel {
    margin-top: 15px !important;
  }
}

.grid-spacer {
  border-bottom: solid 2px var(--primary) !important;
}

.grid-berlin-marker {
  background: #eef !important;
}

.grid-button {
  padding: 3px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-height: 23px;
  position: absolute;
  top: 1px;

  i {
    position: relative;
    top: -2px;
    font-size: 18px !important;
    line-height: unset;
  }
}

gib-confirmation-dialog {
  p {
    text-align: center !important;
  }
}

.ag-icon-menu {
  color: #fff !important;
}

.ag-icon-filter {
  color: var(--secondary) !important;
}

.ag-pinned-left-header {
  border-right: 0px !important;
}

.cal-month-view {
  .cal-day-number {
    margin-bottom: 0;
    float: none;
    position: relative;
    top: 10px;
    right: -75px;
    font-size: 1em !important;
  }

  .cal-today {
    color: #000 !important;
    font-weight: bold !important;
  }

  .cal-day-cell.cal-in-month.cal-has-events {
    cursor: pointer;
    background-color: #c8c618;
  }

  .cal-open-day-events {
    background-color: var(--primary) !important;

    .cal-event-action {
      color: var(--secondary);
    }
  }

  .nv-event {
    color: #ad2121;
    text-align: center;
    font-weight: bold;
    padding-top: 20px;
  }

  .av-event {
    font-size: 12px;
    text-align: center;
    padding-top: 10px;

    div {
      text-align: center;
    }
  }

  .ev-event {
    color: var(--secondary);
    text-align: center;
    font-size: 12px;
  }

  .cal-out-month {
    .custom-month-cell {
      opacity: 0.2;

      .cal-day-number {
        opacity: 1;
      }
    }
  }
}

.center {
  text-align: center;
}

.trainer-feedback-form {
  .mat-form-field-label:not(.mat-form-field-empty) {
    font-size: 120% !important;
  }
}

mat-tab-group {
  .mat-tab-header {
    background: #fff;
    z-index: 10;

    .mat-tab-label.mat-tab-disabled {
      opacity: 1;
    }

    .mat-tab-label:not(:last-child) {
      border-style: none;
    }

    .mat-tab-label-active {
      background: var(--primary);
      opacity: 1;
      color: #fff;
    }

    .mat-ink-bar {
      visibility: hidden !important;
    }
  }
}

.zero-margin {
  margin-left: 0;
  margin-right: 0;
}

.top-margin-nine {
  margin-top: 9px;
}

.mat-simple-snackbar {
  padding: 7px 15px;
}

.gib-mat-border-padding-fix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  border-top: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0.4375em;
  padding-left: 0px;
}

.replaceSelect {
  .mat-select-arrow {
    opacity: 0;
  }
}

faq-expansion-panel {
  .mat-expansion-panel-header-title {
    font-size: larger;
  }
}

add-employee,
add-customer {
  .mat-radio-group {
    float: left;
  }
}

.tooltip > .tooltip-inner {
  background: white !important;
  color: $color-blue !important;
  box-shadow: 0 0 10px #ccc !important;
  width: 400px !important;
  padding: 0.5rem;
}

.tooltip.show {
  opacity: 1;
}

event-customer-data {
  .material-icons {
    cursor: pointer;
    font-size: 30px !important;
  }

  .material-icons:hover {
    color: $color-green;
  }
}

.mat-badge-content {
  background: $color-green;
  left: 13px !important;
}

.mat-expansion-indicator::after {
  color: $color-green;
}

gib-module-selection {
  .mat-select-arrow {
    color: $color-green;
  }
}

// mat-icon handling for custom icons
.mat-icon {
  position: relative;
  top: -5px;
  height: 30px !important;
  width: 30px !important;
  cursor: pointer;
  g {
    fill: $color-blue;
  }
}

.mat-icon:hover {
  g {
    fill: $color-green;
  }
}

gib-toolbar {
  .mat-icon {
    height: 40px !important;
    width: 40px !important;
    g {
      fill: white;
    }
  }
}

gib-module-selection {
  .mat-icon {
    .material-icons {
      font-size: 20px;
    }
    height: 20px !important;
    width: 20px !important;
    svg {
      position: relative;
      top: -6px;
    }
  }
}

preview {
  .mat-icon {
    cursor: default;
  }

  .mat-icon:hover {
    g {
      fill: $color-blue;
    }
  }
}

.profileMenu {
  .dropdown-menu {
    min-width: 200px !important;
  }
}

.customerHsInfoForm {
  gib-slideshow {
    .imgWrapper {
      img {
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
  }
}

.mat-input-element:disabled {
  color: #000000aa;
}
